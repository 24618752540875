/* @import './assets/fonts/Circe-Bold.ttf'; 
@import './assets/fonts/Circe-ExtraBold.ttf'; 
@import './assets/fonts/Circe-Light.ttf'; 
@import './assets/fonts/Circe-Regular.ttf';  */

@font-face {
  font-family: "Circle";
  src: local("Circle"),
   url("./assets/fonts/Circe-Regular.ttf") format("truetype");
  font-weight: normal;
} 
/* @font-face {
  font-family: "Circle";
  src: local("Circle"),
   url("./assets/fonts/Circe-Light.ttf") format("truetype");
  font-weight: lighter;
}  */
/* @font-face {
  font-family: "Circle";
  src: local("Circle"),
   url("./assets/fonts/Circe-Bold.ttf") format("truetype");
  font-weight: bold;
}  */
/* @font-face {
  font-family: "Circle";
  src: local("Circle"),
   url("./assets/fonts/Circe-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
}  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1rem;
  /* font-family: 'Noto Sans', sans-serif; */
  font-family: 'Circle', sans-serif;
}

/* html, body, #root {
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Arial', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw;
} */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Arial,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 1rem;
  margin: 0 auto;
  line-height: 1.496;
  padding: 0;
  height: 100vh;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
/* img {
  width: 100%;
  height: 100%;
} */

input {
  outline: none;
}
ul {
  list-style-type: none;
}



button {
  font-size: 100%;
  /* padding: 20px; */
  cursor: pointer;
  height: fit-content;
}

button:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
@media (min-width: 2000px) {
    button {
      padding: 20px;
    }
}
