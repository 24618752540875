@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.profile {
    display: flex;
    max-width: $maxContentWidth;
    margin: 0 auto;
    padding: 0 2rem;
    width: 100%;
    margin-top: 3rem;
    gap: 1rem;
    background-color: transparent;
    margin-bottom: 2em;
  
   
    &-info {
        width: 100%;
        background-color: $backgroundWhite;
        flex: 1 0 auto;
        .order-not-found {
            font-weight: 600;
            font-size: 1.3em;
            text-align: center;
            margin: 0 auto;
        }
        &__inner {
            display: flex;
            max-width: $maxContentWidth;
            margin: 0 auto;
            padding: 2rem;
            width: 100%;
            flex-direction: column;
            align-items: center;

        }
    }
    &__content-personal-info {
        width: 100%;
        max-width: 400px;
        display: grid;
        grid-template-areas: "phone phone"
                             "fio dob";
        gap: 1em;
        align-self: flex-start;
        .element-phone {
            grid-area: phone;
        }
        .element-fio {
            grid-area: fio;
        }
        .element-dob {
            grid-area: dob;
        }

        .profile__content-personal-info--grid-element.--label {
            color: gray;
        }
        .profile__content-personal-info--grid-element.--data {
            font-weight: bolder;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;

        &-top {
            display: flex;
            flex-direction: row;
            gap: 2em;
            
        }
        &--title {
            font-size: 2.5em;
        }
        &--nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            gap: 1em;

            .profile__nav-item {
                padding: .5em 1em;
                background-color: white;
                border-radius: 10px;
                cursor: pointer;
                color: gray;
                font-weight: 600;
                width: 100%;
                text-align: center;
                &.--active {
                    color: $gold;
                }
                &.logout {
                    background-color: transparent;
                }
            }
        }
        &-bonuses {
            display: flex;
            flex-direction: column;
            font-size: 1.5em;
            span {
                font-weight: bolder;
                font-size: 1.2em;
            }
        }
        &-wallet {
            display: flex;
            flex-direction: row;
            gap: 1em;
            width: 100%;
            max-width: 300px;
            a {
                width: 100%;
                img {
                    width: auto;
                    height: 2.5em;
                }
            }
            
        }
    }

    &__content-order {
        background-color: white;
        padding: 1rem;
        border-radius: 10px;
        width: 100%;
        margin: 2rem 0;
        max-width: 800px;
        border-radius: 10px;
        box-shadow: 1px 3px 7px rgba(28, 28, 28, .25);
        &:first-of-type {
            margin-top: 0;
        }
        &__header {
            display: flex;
            flex-direction: row;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgb(149, 149, 149);
            .order-header-left {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-areas: "number status"
                                     "type type"
                                     "date date";
                align-items: center;
                &__number {
                    color: $backgroundGreen;
                    font-weight: 500;
                    grid-area: number;
                }
                &__status {
                    grid-area: status;
                    font-size: .6rem;

                   
                }
                &__delivery {
                    grid-area: type;
                    font-size: .8rem;
                    font-weight: 500;
                }
                &__date {
                    grid-area: date;
                    font-size: .8rem;
                }
            }
            .order-header-right {
                width: 100%;
               
                .concept-card {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 3rem;
                    }
                    &__info {
                        &--name {
                            font-size: .8rem;
                        }
                        &--address {
                            font-size: .7rem;
                        }
                    }
                }
            }
        }
        .order-products {
            width: 100%;
            &__product {
                display: grid;
                grid-template-columns: 3fr 1fr 1fr;
                * {
                    font-size: .8rem;
                    font-weight: 500;
                    margin: .5rem 0;
                }
                &.--total div {
                    font-size: 1rem;
                    color: $backgroundGreen;
                }
            }
        }
        .order-rate {
            display: inline-block;
            width: 100%;
            text-align: center;
            color: $backgroundGreen;
            cursor: pointer;

            &-form {
                display: flex;
                flex-direction: column;
                gap: .6rem;
                align-items: center;
                label {
                    font-size: .9rem;
                    color: $backgroundGreen;
                }
                &__input {
                    resize: none;
                    outline: none;
                    width: 80%;
                    height: 100px;
                    // min-width: 300px;
                    border: 1px solid $backgroundGreen;
                    border-radius: 10px;
                    padding: .4rem;
                    font-size: .7rem;
                    &:read-only {
                        border: none;
                        color: $backgroundGreen;
                        text-align: center;
                    }
                }
                &__submit {
                    margin: 1rem 0;
                    padding: .5rem;
                    border-radius: 10px;
                    border: none;
                    background-color: $backgroundGreen;
                    color: white;
                }
            }
        }
    }

}

@media (max-width: $mobileWidth) {
    .profile {
        width: 100%;
        &__header--nav {
            flex-direction: column;
        }
        &__header-wallet {
            flex-direction: column;
        }
    }
    .profile__content-order__header {
       flex-direction: column;
    }
}