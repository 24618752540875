@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.delivery-card {
    // width: 32.1%;
    width: 100%;
    height: 100%;
    // max-width: 450px;
    // height: auto;
    position: relative;
    overflow: hidden;
    background-color: $backgroundWhite;
    transition: background-color 0.3s ease;
    &__placeholder {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.338);
        z-index: 100;
        p {
            font-size: 2rem;
            color: white;
            transform: rotate(-45deg) ;
            
        }
        
    }
    &__background {
        width: 100%;
        height: auto;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__header, &__address, &__phone, &__description {
        font-size: 1.3rem;
        font-weight: 500;
        padding: 8px 20px;
        z-index: 2;
        position: relative;
        color: $backgroundGreen;
    }
    &__description {
        font-size: 1rem;
        padding: 5px 20px;
    }
    &__address {
        font-size: 1rem;
        font-weight: bold;
    }
    &__info {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        font-size: 0.8rem;
        padding: 10px 20px;
        position: relative;
        z-index: 2;
        color: $backgroundGreen;
        &--schedule {
            display: flex;
            flex-direction: column;
        }
        &--price {
            display: flex;
            flex-direction: row;
            white-space: nowrap;
            align-items: center;
            &>img{
                width: auto;
                height: 1rem;
                min-height: 20px;
                margin-right: .5rem;
            }
        }
    }

    &__beta {
        position: absolute;
        bottom: -2%;
        right: 5%;
        z-index: 1;
        width: 20%;
        
    }
}

.delivery-card:hover {
    background-color: $backgroundGreen;
    cursor: pointer;
    .delivery-card__placeholder {
        opacity: 1;
    }
    .delivery-card__header, .delivery-card__address, .delivery-card__phone, .delivery-card__info, .delivery-card__description {
        color: $backgroundWhite;
    }
}

@media (max-width: $mobileWidth) {
    .delivery-card {
        width: 100%;
    }
}