@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';

.agreement {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    a {
        color: $backgroundGreen;
    }
    h3 {
        text-align: center;
        font-size: 1.7rem;
    }
}
@media (max-width: $mobileWidth) {
    .agreement {
        padding: 1em;
        width: 100%;
        h3 {
            font-size: 1.2rem;
        }
    }
}