@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.header {
    background-color: $backgroundWhite;
    width: 100%;
    // height: $headerHeight;
    padding: 1rem 0;
    
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    &__inner {
        max-width: $maxContentWidth;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
    }
    &__logo {
        height: 2.7rem;
        width: auto;
        margin-top: auto;
       
    }

    &__signin {
        color: $backgroundGreen;
        font-size: 1.2rem;
    }
    &__username {
        color: $backgroundGreen;
        font-size: 1.2rem;
        // width: 150px;
        text-align: center;
    }
}

.delivery-address-search__dropdown--back {
    position: fixed;
    overflow: hidden;
    display: none;
    width: 1000vw;
    height: 100%;
    top: 0%;
    left: -50%;
    z-index: 999;
    background-color: black;
    opacity: 0.5;

    &.open {
        display: block;
    }
}

@media (max-width: $mobileWidth) {
    .header {
        width: 100%;

        position: relative;

        &__inner {
            width: 100%;
            // padding: 0;
        }
    }
    .delivery-address-search {
        display: none;
    }
}