@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.menu-items {
    width: 100%;
    scroll-margin-top: 50px;
    &__header {
        color: $backgroundGreen;
        font-weight: 500;
        font-size: 1.7rem;
        margin: .5rem 0;
    }
    &__desc, &__not-found{
        color: $backgroundGreen;
        font-weight: 500;
        font-size: 1rem;
    }
    
}

@media (max-width: $mobileWidth) {
    .menu-items {
        padding: 0 20px;
    }
}