@import "../../utils/colors.scss";
@import "../../utils/sizes.scss";
.footer {
    background-color: $backgroundGreen;

    &.--has-cart {
        margin-bottom: 80px;
    }
    width: 100%;
    display: flex;
    flex: 0 1 auto;
    margin: 0 auto;
    flex-direction: column;
    &__inner {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding: 2rem;
        display: flex;
        gap: 1rem;
        flex-direction: row;
        justify-content: space-between;
    }
    &__addresses {
        display: flex;
        flex-direction: column;
        gap: .5em;
        color: $fontWhite;
        h2 {
            font-weight: 500;
            font-size: 24px;
            color: white;
        }
        &>div {
            display: flex;
            flex-direction: column;
        }
    }
    &__links {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        width: 100%;
    }
    &__docs {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    &__link {
        color: white;
        font-size: 20px;
        font-weight: 500;
    }
    &__app {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 1em;
        width: 100%;
        transform: translateY(2rem);
        & a img {
            width: auto;
            height: 40px;
        }
        &-mobile {
            width: 10em;
            height: auto;

            &-links {
                display: grid;
                grid-template-areas: "msg msg"
                                     "gp as";
                padding-bottom: 1em;
                gap: 1em;
                max-width: 300px;
                .mobile-links-header {
                    grid-area: msg;
                    color: white;
                    font-size: 1.2em;
                    font-weight: 500;
                }
            }
        }
    }
    &__cw {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $backgroundGray;
        &-inner {
            max-width: 1280px;
            width: 100%;
            margin: 0 auto;
            padding: 1rem 2rem;
            display: flex;
            gap: 1rem;
            flex-direction: row;
            justify-content: space-between;
        }
        &-socials {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            a {
                display: flex;
                align-items: center;
            }
            img {
                width: 2em;
                height: 2em;
            }
        }
        &-work-on {
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;
            white-space: nowrap;
            color: #9E9E9E;
            font-weight: 500;
            font-size: .8rem;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img {
                height: 1em;
                width: auto;
            }
        }
    }
}

@media (max-width: $mobileWidth) {
    .footer__inner {
        display: flex;
        // height: auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding: 1rem;
        gap: 1rem;
    }
    .footer__app {
        display: none;
    }
    .footer {
        &.--has-cart {
            margin-bottom: 0px;
        }
    }
    .footer__cw-inner {
        flex-direction: column;
        padding: 1rem;
        align-items: center;
    }
    .footer__cw-work-on {
        flex-direction: column;
        white-space: nowrap;
    }
}