@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.modal-product {
    // max-width: 1400px;
    max-width: $maxContentWidth;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: $backgroundGray;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    &__back {
        padding: 1rem;
        color: $backgroundGreen;
        font-size: 1.1rem;

    }
    &__info {
        width: 100%;
        // height: 50%;
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
        margin-bottom: 2rem;

        &--badges {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;

            &-badge {
                height: 1.5rem;
                display: flex;
                flex-direction: row;
                gap: .5rem;

                &-img {
                    border-radius: 50%;
                }
            }
        }

        &-img {
            width: 100%;
            // height: 40%;
            height: min-content;
            img {
                width: 100%;
                height: auto;
            }
            position: relative;
        
            &--items {
                display: none;
            }
            &.--has-items {
                .modal-product__info-img--items {
                    background-color: rgba($backgroundGreen, 0.4);
                    // img {
                    //     filter: grayscale(100%)
                    // }
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    // display: flex;
                    // flex-direction: column;
                    z-index: 2;

                    .image-wrapper-item {
                        width: 100%;
                        padding: .8rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        
                        &__name, small {
                            color: $backgroundWhite;
                            font-size: .7rem;
                            font-weight: 500;
                        }

                        &__counter {
                            // width: 70px;
                            height: 30px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;
                            gap: 1rem;
                            color: $backgroundWhite;

                            &--btn-counter {
                                width: 20px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                color: $backgroundWhite;
                                border: 2px solid $backgroundWhite;
                                background-color: rgba(0, 0, 0, 0);
                                font-size: .6rem;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            
            // img {
            //     // width: auto;
            //     max-width: 100%;
            //     height: auto;
            //     max-height: inherit;

            // }
        }
        &-content {
            width: 100%;
            display: grid;
            grid-template-areas: "header header"
                                 "desc desc"
                                 "mods mods"
                                //  "energy nutrition"
                                //  "weight none"
                                 "price btn";
            // grid-template-rows: .5fr 1fr 2fr   1fr;
            gap: 1rem;
            &--header {
                grid-area: header;
                color: $backgroundGreen;
                font-size: 1.3rem;
                font-weight: 500;
            }
            &--description {
                grid-area: desc;
                color: gray;
            }
            &--mods {
                grid-area: mods;
                
                .product-modifiers {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    h2 {
                        color: $backgroundGreen;
                        font-size: 1rem;
                    }

                    &__options {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: .7rem;

                        &-option {
                            border: 1px solid $backgroundGreen;
                            color: $backgroundGreen;
                            border-radius: 10px;
                            padding: 2px 10px;
                            
                            &.--choosen {
                                background-color: $backgroundGreen;
                                color: white;
                            }
                        }
                    }
                }
            }
            &--energy-value {
                grid-area: energy;
                color: $backgroundGreen;
                font-size: 0.9rem;
                span {
                    color: gray;
                    font-size: .85rem;
                }
            }
            &--nutrition {
                grid-area: nutrition;
                color: $backgroundGreen;
                font-size: 0.9rem;
                span {
                    color: gray;
                    font-size: .85rem;
                }
            }
            &--weight {
                grid-area: price;
                color: gray;
                font-size: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &--price {
                grid-area: price;
                color: $backgroundGreen;
                font-size: 1rem;
                font-weight: bold;

            }
            &--add-to-cart {
                grid-area: btn;
                border: 0;
                background-color: $backgroundGreen;
                color: $backgroundWhite;
                border-radius: 10px;
                cursor: pointer;
                padding: 1rem;
                align-self: center;
                // max-height: 40px;
            }
            &--counter {
                grid-area: btn;
                width: 100%;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                button {
                    border-radius: 50%;
                    border: 0;
                    background-color: $backgroundGreen;
                    width: 40px;
                    height: 40px;
                    color: $backgroundWhite;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    padding: 0;
                }
                span {
                    display: flex;
                    // width: 50%;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    color: $backgroundGreen;
                    font-weight: 500;
                }
            }
        }
    }
}


@media (max-width: $mobileWidth) {
    .modal-product {
        width: 100%;
        min-width: 0;

        &__info {
            display: flex;
            flex-direction: column;
        }
    }
}